<template>
  <VueFinalModal name="pincode_confirm">
    <ModalBase name="pincode_confirm" titleIcon="alert" title="Подтверждение">
      <div class="modal-pincode">
        <div class="modal-pincode__text">
          Никому не сообщайте данный код, даже сотрудникам нашей администрации.<br /><br />
          <span class="purple">Если у вас кто-то спрашивает этот код - это мошенник!</span>
        </div>
        <div class="modal-pincode__btns">
          <VButton color="light" size="48"> Отмена </VButton>
          <VButton size="48"> Согласен </VButton>
        </div>
      </div>
    </ModalBase>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from "vue-final-modal";
import { PinInput } from "v-pin-input";

const code = ref([]);
</script>

<style lang="scss">
.modal-pincode {
  width: 322px;
  max-width: 100%;
  color: var(--color_main__100);

  &__text {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 137.3%;
    margin-bottom: 20px;

    .purple {
      color: var(--color_link__100);
    }
  }

  &__btn {
    width: 100%;
    margin-top: 20px;
  }

  &__btns {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
</style>
